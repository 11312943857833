import * as React from "react"
import * as styles  from '../styles/pages/404/index.module.css'

const NotFoundPage = () => {
  const { sIntroSection, sIntro, sIntroDesc  } = styles;
  return (
            <article>
                <section className={sIntroSection}> 
                    <div className={sIntro}>
                        <div className={sIntroDesc}>
                                <h1 >404</h1>
                                <h2 >Page not found</h2>
                                <h2 >העמוד לא נמצא</h2>
                        </div> 
                  </div>       
                </section>
            </article>
  )
}

export default NotFoundPage
